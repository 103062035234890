import { usePageContext } from 'libs/notion-renderer/PageProvider';
import { getAssetSrc, getHref, InvalidLink } from 'libs/notion-renderer/utils';
import VideoLink from 'molecules/VideoLink';
import { NotionProps } from 'notion/types';

const NotionVideoLink: React.FC<NotionProps> = ({ block }) => {
  const { assets, pagePath, headings, pageMetas } = usePageContext();
  const { url, text, ratio, mobileRatio, alt, poster, srcWebM, srcMp4 } = block;

  const assetSrcPoster = getAssetSrc(pagePath, poster, assets);
  const assetSrcWebM = getAssetSrc(pagePath, srcWebM, assets);
  const assetSrcMp4 = getAssetSrc(pagePath, srcMp4, assets);

  let href;

  try {
    href = getHref(url, pageMetas, headings);
  } catch (e) {
    if (e instanceof Error) {
      throw new InvalidLink(pagePath, text, e.message);
    }
    throw e;
  }

  return (
    <VideoLink
      text={text}
      href={href}
      ariaLabel={alt}
      aspectRatio={{ default: ratio, mobile: mobileRatio }}
      poster={assetSrcPoster}
      srcWebM={{ default: assetSrcWebM }}
      srcMp4={{ default: assetSrcMp4 }}
    />
  );
};

export default NotionVideoLink;
