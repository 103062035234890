import { Video, VideoProps } from '@collab/atoms';

import CardLink from 'atoms/CardLink/CardLink';

import useAnimatedVideo from './useAnimatedVideo';

type VideoLinkProps = VideoProps & {
  href: string;
  text: string;
};

const VideoLink: React.FC<VideoLinkProps> = ({ href, text, ...videoProps }) => {
  const { videoWrapperRef } = useAnimatedVideo();

  return (
    <div ref={videoWrapperRef}>
      <CardLink href={href} text={text}>
        <Video
          controls="none"
          disableRemotePlayback
          {...videoProps}
          autoPlay={false}
        />
      </CardLink>
    </div>
  );
};

export default VideoLink;
