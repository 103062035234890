import { useCallback, useEffect, useState } from 'react';

import { useBreakpoints, useInView } from '@vcc-www/hooks';

const useAnimatedVideo = () => {
  // eslint-disable-next-line vcc-www/use-breakpoints
  const { untilM } = useBreakpoints();
  const [isMounted, setIsMounted] = useState(false);
  const [videoWrapperRef, inView] = useInView<HTMLDivElement>({
    triggerOnce: false,
    rootMargin: '-50% 0px',
  });

  const play = useCallback(
    async (videoEl: HTMLVideoElement) => {
      try {
        if (isMounted && !videoIsPlaying(videoEl)) {
          videoEl.loop = true;
          await videoEl.play();
        }
      } catch (e) {
        if (isMounted) {
          videoEl.pause();
        }
      }
    },
    [isMounted],
  );

  const pause = useCallback((videoEl: HTMLVideoElement) => {
    if (videoIsPlaying(videoEl)) {
      videoEl.loop = false;
    }
  }, []);

  useEffect(() => {
    const videoEl = videoWrapperRef?.current?.querySelector('video');

    if (!videoEl) return;

    const onMouseEnter = () => play(videoEl);
    const onMouseLeave = () => pause(videoEl);

    setIsMounted(true);

    videoEl.autoplay = untilM;

    if (untilM) {
      inView ? play(videoEl) : pause(videoEl);
    } else {
      videoEl.addEventListener('mouseenter', onMouseEnter);
      videoEl.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      setIsMounted(false);

      videoEl.removeEventListener('mouseenter', onMouseEnter);
      videoEl.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [videoWrapperRef, inView, pause, play, untilM]);

  return {
    videoWrapperRef,
  };
};

const videoIsPlaying = (video: HTMLVideoElement): boolean =>
  video.currentTime > 0 &&
  !video.paused &&
  !video.ended &&
  video.readyState > video.HAVE_CURRENT_DATA;

export default useAnimatedVideo;
