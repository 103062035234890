import { Track } from '@volvo-cars/tracking';

import { Link } from '@collab/atoms';

import styles from './CardLink.module.css';

type CardLinkProps = {
  href: string;
  text: string;
  children: React.ReactNode;
};

const CardLink: React.FC<CardLinkProps> = ({ href, text, children }) => (
  <Track eventAction="link|click" eventLabel={href}>
    <Link href={href} className={`${styles.wrapper} flex-col`}>
      {children}
      <p className={`${styles.linkButton} button-text`} data-color="accent">
        {text}
      </p>
    </Link>
  </Track>
);

export default CardLink;
